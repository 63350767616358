import { useMixpanel, useNotify } from '@/composables/index';

export default function useGMAd(warehouse, org, entryPoint) {
  const notify = useNotify();
  const mixpanel = useMixpanel();
  const baseMixpanelData = {
    'Warehouse ID': warehouse.id,
    'Warehouse Name': warehouse.name,
    'Org ID': warehouse.orgId,
    'Org Name': org.name
  };
  const handleGMCTAClick = () => {
    mixpanel.track('Clicked: Gate Management CTA', {
      ...baseMixpanelData,
      'Entry Point': entryPoint
    });
  };

  const handleGMRequestClick = () => {
    mixpanel.track('Action: Gate Management Requested', {
      ...baseMixpanelData,
      'Entry Point': entryPoint
    });
    notify('Thank you! Our team will contact you soon.', 'success');
  };

  return {
    handleGMCTAClick,
    handleGMRequestClick
  };
}
