import { createFeatureFlagPlugin, useClient } from '@loadsmart/vue-feature-flags';

import config from '@/config/config';
import { $eventHub } from '@/eventHub';

/**
 * Get the Authorization header for the feature flag client.
 * @returns {string}
 */
export function getAuthHeader() {
  const key = 'access_token';
  const token = sessionStorage?.getItem(key) || localStorage?.getItem(key);
  return `Bearer ${token || ''}`;
}

/**
 * Custom fetch for the feature flag client.
 * It will inject the current authorization header for the user instead
 * of using an static value.
 * @param {string | URL} url
 * @param {RequestInit} init
 * @returns {Response}
 */
function customFetch(url, init) {
  init = init || {};
  init.headers = init.headers || {};
  init.headers.Authorization = getAuthHeader();
  return fetch(url, init);
}

/**
 * When there's either a login or a logout event we need
 * to restart the feature flag plugin client to guarantee
 * that it's using the freshest user data.
 */
async function restartClient() {
  const { client } = useClient();

  try {
    client?.stop();
    await client?.start();
  } catch (error) {
    console.error('[featureFlagPlugin] failed to restart client', error);
  }
}

$eventHub.$on('auth/getMe', restartClient);
$eventHub.$on('auth/logout', restartClient);

export default createFeatureFlagPlugin({
  config: {
    url: config.unleash_url,
    appName: config.unleash_app_name,
    clientKey: getAuthHeader(),
    environment: config.server_env === 'production' ? 'production' : 'development',
    fetch: customFetch,
    disableMetrics: true,
    usePOSTrequests: true
  },
  /**
   * With auto start enabled we might start fetching the feature flags
   * before the user logs in, in which case we will restart the client
   * to refetch the flags as the user, but this can cause a "flicker"
   * in the screen where we show the disabled version and then the enabled
   * one.
   * To prevent this auto start has been disable as we haven't had the need
   * for feature flag when the user is not logged in.
   */
  disableAutoStart: true
});
