<template>
  <div>
    <appointments-loader
      :is-loading="isLoading"
      :custom-message="
        !searchOptions.scheduledStart || !searchOptions.scheduledEnd
          ? 'For better performance, try adding a date range to your search...'
          : null
      "></appointments-loader>

    <v-card class="appointment-search">
      <div class="search-bar">
        <v-card-title class="pb-4">
          <v-row align="start">
            <v-col xs="6" md="7" class="is-relative">
              <div class="is-relative">
                <text-field
                  v-on:keyup.enter="search"
                  outlined
                  class="multi-icon"
                  autofocus
                  clearable
                  clear-icon="mdi-close"
                  v-model="searchOptions.searchStr"
                  height="30"
                  data-testid="outer-search-input-wrapper"
                  label="Search by Reference Number, Confirmation Number, Tags..."
                  placeholder="Has the Words..."
                  hide-details="auto"
                  prepend-inner-icon="mdi-magnify"></text-field>
                <v-btn class="field-button" x-small plain text @click="toggleSearchOptions">
                  <v-icon small :class="{ 'secondary--text': canSearch && hasOptionsDiff }">
                    {{
                      canSearch && hasOptionsDiff
                        ? 'mdi-filter-multiple'
                        : 'mdi-filter-multiple-outline'
                    }}
                  </v-icon>
                  Filter
                </v-btn>
              </div>
              <div
                class="search-options-container"
                v-if="shouldShowSearchOptions"
                v-on-clickaway="hideSearchOptions">
                <div class="search-fields py-6 px-9">
                  <div>
                    <span class="font-weight-bold font-size-small">
                      {{ optionLabels.searchStr }}:
                    </span>
                    <text-field
                      v-on:keyup.enter="search"
                      v-model="searchOptions.searchStr"
                      height="30"
                      hide-details="auto"
                      class="mb-6"></text-field>
                  </div>

                  <div>
                    <span class="font-weight-bold font-size-small">Booked At:</span>
                    <warehouse-multi-select
                      class="mb-6"
                      :joins="['docks||capacityChildren,name,isActive,loadTypeIds']"
                      v-model="selectedWarehouses"
                      placeholder="Booked at Warehouses"
                      :show-create-warehouse-dialog="false"
                      :show-create-dock-dialog="false"
                      hide-icon></warehouse-multi-select>

                    <dock-select
                      hide-icon
                      class="mb-6"
                      :disabled="!selectedWarehouses.length"
                      v-model="selectedDocks"
                      :multiple="true"
                      :hide-inactive-docks="false"
                      placeholder="Booked at Docks"
                      :docks="availableDocks"></dock-select>
                  </div>

                  <div>
                    <span class="font-weight-bold font-size-small">Carrier contacts:</span>
                    <carrier-select
                      multiple
                      :show-carrier-create-button="false"
                      hide-icon
                      hide-details
                      v-model="searchOptions.users"></carrier-select>
                    <v-spacer></v-spacer>
                  </div>

                  <div>
                    <span class="font-weight-bold font-size-small">
                      {{ optionLabels.refNumber }}:
                    </span>
                    <text-field
                      v-on:keyup.enter="search"
                      v-model="searchOptions.refNumber"
                      height="30"
                      hide-details="auto"
                      class="mb-6"></text-field>
                  </div>

                  <div>
                    <span class="font-weight-bold font-size-small">
                      {{ optionLabels.customFields }}:
                    </span>
                    <text-field
                      v-on:keyup.enter="search"
                      v-model="searchOptions.customFields"
                      height="30"
                      hide-details="auto"
                      class="mb-6"></text-field>
                  </div>

                  <div>
                    <span class="font-weight-bold font-size-small">{{ optionLabels.tags }}:</span>
                    <text-field
                      v-on:keyup.enter="search"
                      v-model="searchOptions.tags"
                      height="30"
                      hide-details="auto"
                      class="mb-6"></text-field>
                  </div>

                  <div>
                    <span class="font-weight-bold font-size-small">{{ optionLabels.notes }}:</span>
                    <text-field
                      v-on:keyup.enter="search"
                      v-model="searchOptions.notes"
                      height="30"
                      hide-details="auto"
                      class="mb-6"></text-field>
                  </div>

                  <div class="d-flex align-end">
                    <span class="d-inline-block mr-2 font-weight-bold font-size-small">
                      {{ optionLabels.startDate }}:
                    </span>
                    <date-picker
                      class="flex-1"
                      inner-icon
                      display-field-icon
                      :ref="`datepicker-start`"
                      no-margins
                      v-model="searchOptions.startDate"
                      :clearable="true"
                      placeholder=" "></date-picker>
                    <span class="d-inline-block ml-4 mr-2 font-weight-bold font-size-small">
                      {{ optionLabels.endDate }}:
                    </span>
                    <date-picker
                      class="flex-1"
                      inner-icon
                      display-field-icon
                      :ref="`datepicker-end`"
                      no-margins
                      v-model="searchOptions.endDate"
                      :clearable="true"
                      placeholder=" "></date-picker>
                  </div>

                  <div class="mt-6">
                    <span class="font-weight-bold font-size-small">
                      {{ optionLabels.statuses }}:
                    </span>
                    <status-select
                      v-model="searchOptions.statuses"
                      :should-disable-auto-select="true"
                      :single-line="false"
                      placeholder=" "
                      :select-all="false"
                      hide-icon></status-select>
                  </div>
                </div>

                <div class="d-flex justify-end search-actions py-5 px-9">
                  <outline-button class="mr-2" @click="clearOptions" :disabled="!canSearch">
                    Clear All
                  </outline-button>
                  <primary-button
                    :disabled="!canSearch"
                    @click="
                      search();
                      toggleSearchOptions();
                    ">
                    Search
                  </primary-button>
                </div>
              </div>
            </v-col>

            <v-col xs="2">
              <primary-button data-testid="outer-search" @click="search" :disabled="!canSearch">
                Search
              </primary-button>
            </v-col>
            <v-col xs="4" md="3" class="text-right">
              <date-range
                data-testid="search-date-range"
                clearable
                label="Start date between"
                outlined
                :initial-range="[searchOptions.scheduledStart, searchOptions.scheduledEnd]"
                ref="dateRange"
                @input="startDateRangeChanged"></date-range>
              <a href="#" @click="clearDateRange" class="text-caption">Clear date range</a>
            </v-col>
          </v-row>
        </v-card-title>

        <v-alert color="warning lighten-2" v-if="totalItems > 9999" dense>
          <div class="d-flex align-center">
            <v-icon class="mr-1">mdi-alert</v-icon>
            Your search returned 10,000 results. Try adding more filters to narrow it down more.
          </div>
        </v-alert>

        <div
          class="selected-options-container"
          v-if="Object.keys(currentSearch).length > 0 && !shouldShowSearchOptions">
          <template v-for="(option, key) in currentSearch">
            <template v-if="Array.isArray(currentSearch[key]) && currentSearch[key].length > 0">
              <v-row :key="`search-option-${key}`">
                <v-col class="font-size-x-small py-0">
                  <strong>{{ optionLabels[key] }}:</strong>
                  <template v-for="(arrayVal, index) in currentSearch[key]">
                    <v-chip
                      small
                      class="ma-1"
                      close
                      color="secondary"
                      :key="`search-option-${key}-${index}`"
                      @click:close="removeArrEl(key, index, arrayVal)">
                      {{ getArrValue(key, arrayVal) }}
                    </v-chip>
                  </template>
                </v-col>
              </v-row>
            </template>
            <template
              v-else-if="
                !Array.isArray(currentSearch[key]) && currentSearch[key] && optionLabels[key]
              ">
              <v-row :key="`search-option-${key}`">
                <v-col class="font-size-x-small py-0">
                  <strong>{{ optionLabels[key] }}:</strong>
                  <v-chip
                    small
                    class="ma-1"
                    close
                    color="secondary"
                    @click:close="
                      searchOptions[key] = '';
                      search();
                    ">
                    {{ currentSearch[key] }}
                  </v-chip>
                </v-col>
              </v-row>
            </template>
          </template>
        </div>
      </div>

      <v-data-table
        :headers="headers"
        :items.sync="appointments"
        :server-items-length="totalItems"
        ref="appointmentSearchTable"
        :sort-desc.sync="sort.sortDesc"
        :custom-filter="searchRows"
        item-key="id"
        :page.sync="page"
        :expanded.sync="expanded"
        :footer-props="footerProps"
        class="clickable-rows"
        :options.sync="options"
        @update:sort-by="sortByVal => (sort.sortBy = sortByVal)">
        <template v-for="header in headers" v-slot:[`item.${header.value}`]="{ item, value }">
          <template v-if="header.value === 'user.email'">
            <copy-content :key="header.value" :content="value" label="Click to copy carrier email">
              <span :inner-html.prop="value"></span>
            </copy-content>
          </template>
          <template v-else-if="header.value === 'user'">
            <span :key="header.value">
              {{ item.user.firstName }} {{ item.user.lastName }} @
              <span v-if="item.user?.company?.name">{{ item.user.company.name }}</span>
            </span>
          </template>
          <template v-else-if="header.value === 'dock.name'">
            <span :key="header.value">
              {{ novaCore.getDockNameWithActiveStatus(item.dock) }}
            </span>
          </template>
          <template v-else>
            <span :key="header.value" :inner-html.prop="value"></span>
          </template>
        </template>
        <template v-slot:item.status="{ item }">
          <strong
            :inner-html.prop="novaCore.breakWordsAtCaps(item.status)"
            :class="`${item.status.toLowerCase()} tile font-size-x-small`"></strong>
        </template>

        <template v-slot:item.actions="{ item, index }">
          <div class="row-actions">
            <icon-tooltip-button
              v-if="index !== detailsIconLoadingIndex"
              :disabled="detailsIconLoadingIndex !== null && index !== detailsIconLoadingIndex"
              icon-class="mr-2"
              size="large"
              @click="handleEventClick(item, index)"
              tooltip="View Appointment"
              icon="eye"></icon-tooltip-button>
            <v-progress-circular indeterminate v-else color="primary" size="18" class="mr-2" />
            <icon-tooltip-button
              size="large"
              tooltip="Show More"
              @click="toggleRowExpansion(item)"
              :icon="expanded.includes(item) ? 'chevron-up' : 'chevron-down'"></icon-tooltip-button>
          </div>
        </template>

        <template v-slot:expanded-item="{ headers, item }" v-if="headers && headers.length">
          <td :colspan="headers.length" class="py-6">
            <h3>Notes:</h3>
            <div v-html="item.notes"></div>
            <v-divider class="my-5"></v-divider>

            <h3>Tags</h3>

            <template v-for="tag in item.tags">
              <v-chip
                :key="tag"
                class="px-2 mr-1"
                label
                :text-color="util.makeTagObject($org.customTags, tag).textColor"
                :color="util.makeTagObject($org.customTags, tag).color">
                <span
                  :inner-html.prop="
                    util.makeTagObject($org.customTags, tag).name
                      | highlight(regexSafeSearch, item.id, 'tag')
                  "></span>
              </v-chip>
            </template>

            <span v-if="!item.tags || !item.tags.length">This appointment does not have tags</span>

            <v-divider class="my-5" v-if="appointmentTriggers(item)?.length"></v-divider>
            <div v-for="trigger of appointmentTriggers(item)" :key="trigger.id" class="mb-2">
              <h3 class="font-weight-bold">
                {{ trigger.flow?.name || novaCore.CustomFormsFeaturesData[trigger.feature].title }}
              </h3>
              <custom-forms-data
                :key="trigger.id"
                :trigger="trigger"
                :object-id="item.id"
                :timezone="item.dock.warehouse.timezone"
                flat
                :military-time-enabled="
                  $isMilitaryTimeEnabled(item.dock.warehouse)
                "></custom-forms-data>
            </div>
          </td>
        </template>

        <template v-slot:item.start="{ item }">
          <span>
            {{
              getTimeInWarehouseTimezone(
                item,
                `${novaCore.LuxonDateTimeFormats.LongDateShortMonth} @ ${novaCore.LuxonDateTimeFormats.Extended12HrTimeAMPM} - ${novaCore.LuxonDateTimeFormats.AbbreviatedNamedOffset}`,
                `${novaCore.LuxonDateTimeFormats.LongDateShortMonth} @ ${novaCore.LuxonDateTimeFormats.Extended24HrTime} - ${novaCore.LuxonDateTimeFormats.AbbreviatedNamedOffset}`,
                $isMilitaryTimeEnabled($org)
              )
            }}
          </span>
        </template>

        <template v-slot:no-data>
          <v-alert color="disabled" class="mt-3" v-if="!canSearch">
            No search filters
            <v-btn icon @click="toggleSearchOptions">
              <v-icon>mdi-filter-outline</v-icon>
            </v-btn>
            have been added yet
          </v-alert>
          <div v-else class="mt-3">
            <p
              v-if="!searchOptions.scheduledStart || !searchOptions.scheduledEnd"
              class="text-subtitle-2 py-4">
              No data available. Please try adding a
              <strong>date range</strong>
              to your search.
            </p>
            <p v-else class="text-subtitle-2 py-4">No data available.</p>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <feature-flag name="enable-new-appointment-details">
      <template #enabled>
        <appointment-details-dialog
          v-if="selectedAppointment"
          :external-activator="true"
          :show-dialog="showDetailsDialog"
          :context="selectedAppointment"
          @close="handleDetailsDialogClose"
          :appointment="selectedAppointment"></appointment-details-dialog>
      </template>
      <template #disabled>
        <appointment-details-dialog-old
          v-if="selectedAppointment"
          :external-activator="true"
          :show-dialog="showDetailsDialog"
          :context="selectedAppointment"
          @close="handleDetailsDialogClose"
          :appointment="selectedAppointment"></appointment-details-dialog-old>
      </template>
    </feature-flag>
  </div>
</template>

<script>
// TODO: Lots of duplicated code between this and the appointmentList component.  Need to abstract
import { mixin as clickaway } from 'vue-clickaway';
import { isMobile } from '@satellite/plugins/util';
import recurringAppointmentMixin from '@/components/mixins/recurringAppointmentMixin';
import { DateTime } from 'luxon';
import { isDateString } from 'class-validator';
import { getTimeInWarehouseTimezone } from '@satellite/plugins/util';

const MINIMUM_SEARCH_CHARS = 3;
const DEFAULT_DATE_RANGE_DAYS = 14;

export default {
  mixins: [clickaway, recurringAppointmentMixin],
  computed: {
    isDateRangeSelected() {
      return (
        isDateString(this.searchOptions.scheduledStart) &&
        isDateString(this.searchOptions.scheduledEnd)
      );
    },
    dateRangeDays() {
      if (!this.isDateRangeSelected) {
        return 0;
      }

      const startDT = DateTime.fromISO(this.searchOptions.scheduledStart);
      const endDT = DateTime.fromISO(this.searchOptions.scheduledEnd);

      return endDT.diff(startDT, 'day').days;
    },
    responseData() {
      return this.searchResults?.data ?? {};
    },
    appointments() {
      return this.responseData?.items ?? [];
    },
    totalItems() {
      return this.responseData?.total ?? 0;
    },
    canSearch() {
      let canSearch = false;
      const searchOptionsCopy = this.novaCore.deepClone(this.searchOptions);

      delete searchOptionsCopy.scheduledStart;
      delete searchOptionsCopy.scheduledEnd;

      Object.keys(searchOptionsCopy).forEach(key => {
        if (Array.isArray(searchOptionsCopy[key]) && searchOptionsCopy[key].length === 0) {
          delete searchOptionsCopy[key];
        } else if (!Array.isArray(searchOptionsCopy[key]) && !searchOptionsCopy[key]) {
          delete searchOptionsCopy[key];
        }
      });

      // Specialized search rules
      if (Object.keys(searchOptionsCopy).length !== 0) {
        canSearch = true;
        // force wildcard test search to minimum of 3 characters
        // including other free text search fields
        const freeTextFields = ['searchStr', 'customFields', 'refNumber', 'notes', 'tags'];
        if (
          freeTextFields.some(
            field =>
              this.novaCore.objPropExists(searchOptionsCopy, field) &&
              searchOptionsCopy[field].length < MINIMUM_SEARCH_CHARS
          )
        ) {
          canSearch = false;
        }
      }

      return canSearch;
    },
    hasOptionsDiff() {
      return JSON.stringify(this.searchOptions) !== JSON.stringify(this.currentSearch);
    },
    headers() {
      if (this.buildHeadersFn) {
        return this.buildHeadersFn();
      }

      return [
        {
          text: 'Start Time',
          value: 'start'
        },
        {
          text: 'Location',
          value: 'dock.warehouse.name',
          searchable: true,
          align: ' d-none'
        },
        {
          text: 'Carrier',
          value: 'user'
        },
        {
          text: 'Warehouse',
          value: 'dock.warehouse.name'
        },
        {
          text: 'Dock',
          value: 'dock.name'
        },
        {
          text: 'Load Type',
          value: 'loadType.name'
        },
        {
          text: 'Tags',
          value: 'tags',
          align: ' d-none'
        },
        {
          text: 'Custom Fields',
          value: 'customFields',
          align: ' d-none'
        },
        {
          text: this.$getSettingValue('referenceNumberDisplayName', this.$org?.settings),
          value: 'refNumber'
        },
        {
          text: 'Status',
          value: 'status'
        },
        {
          text: 'Confirmation #',
          value: 'confirmationNumber'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          align: 'end'
        }
      ];
    },
    availableDocks() {
      return this.selectedWarehouses.length > 0
        ? this.selectedWarehouses.map(warehouse => warehouse.docks).flat()
        : this.docks;
    },
    optionLabels() {
      return {
        refNumber: this.$getSettingValue('referenceNumberDisplayName', this.$org?.settings),
        searchStr: 'Contains the Words',
        notes: 'Notes Contain',
        tags: 'Tags Have',
        customFields: 'Custom Fields Contains',
        startDate: 'After',
        endDate: 'Before',
        statuses: 'Has Status',
        dockIds: 'Booked at Dock(s)',
        users: 'Carrier Contacts'
      };
    }
  },
  data() {
    return {
      detailsIconLoadingIndex: null,
      selectedAppointment: null,
      selectedWarehouses: [],
      selectedDocks: [],
      searchOptions: {
        refNumber: '',
        searchStr: '',
        notes: '',
        tags: '',
        customFields: '',
        startDate: '',
        endDate: '',
        statuses: [],
        dockIds: [],
        users: [],
        scheduledStart: this.defaultStartDate(),
        scheduledEnd: this.defaultEndDate()
      },
      currentSearch: {},
      shouldShowSearchOptions: false,
      size: 50,
      from: 0,
      searchResults: {},
      warehouses: [],
      docks: [],
      isLoading: false,
      expanded: [],
      sort: {
        sortBy: 'appointment.start',
        sortDesc: false
      },
      page: 1,
      // TODO: options and footerProps ire currently duplicated from dataListMixin.js, need to make
      // these common as part of refactoring this AppointmentsSearchResults.vue component. (An older
      // TODO comment in this file points out there is much duplication that can be fixed with a refactor)
      options: {
        itemsPerPage: 15
      },
      footerProps: {
        itemsPerPageOptions: [5, 10, 15, 30],
        showFirstLastPage: true
      },
      warehouseTriggers: [],
      showDetailsDialog: false,
      showReserveDialog: false
    };
  },
  methods: {
    getTimeInWarehouseTimezone,
    appointmentTriggers(item) {
      return this.warehouseTriggers.filter(
        t => t.objectId === item.dock.warehouseId && t.entityName === 'warehouse'
      );
    },
    handleDetailsDialogClose() {
      this.selectedAppointment = null;
      this.showDetailsDialog = false;
      this.showReserveDialog = false;
    },
    defaultStartDate() {
      return DateTime.now()
        .minus({ day: Math.floor(DEFAULT_DATE_RANGE_DAYS / 2) })
        .toISODate();
    },
    defaultEndDate() {
      return DateTime.now()
        .plus({ day: Math.floor(DEFAULT_DATE_RANGE_DAYS / 2) })
        .toISODate();
    },
    startDateRangeChanged([firstDate, lastDate]) {
      this.searchOptions.scheduledStart = firstDate;
      this.searchOptions.scheduledEnd = lastDate;
      this.search();
    },
    clearDateRange(e) {
      e.preventDefault();
      this.$refs.dateRange.setDates([]);
    },
    async handleEventClick(item, index) {
      this.detailsIconLoadingIndex = index;
      item.readableDockName = item.dock.name;
      try {
        const warehouse = await this.services.warehouse.getWarehouseById(
          item.dock.warehouseId,
          {},
          {
            fields: this.services.warehouse.requestOptions.fields,
            joins: ['docks']
          }
        );
        const appointment = await this.services.appointment.getAppointmentById(
          item.id,
          {},
          {
            joins: [
              'dock||name,loadTypeIds',
              'loadType||name',
              'user||firstName,lastName,email,phone',
              'user.company||name,scac'
            ]
          }
        );
        appointment.dock.warehouse = warehouse;
        this.selectedAppointment = appointment;
        if (this.novaCore.isReserve(item)) {
          this.showReserveDialog = true;
        } else {
          this.showDetailsDialog = true;
        }
      } finally {
        this.detailsIconLoadingIndex = null;
      }
    },
    searchRows(value, search, item) {
      if (!value) {
        return false;
      }
      if (!search) {
        return true;
      }
      let hasMatch = false;
      let hasExpandedMatch = false;
      const isArr = Array.isArray(value);

      const s = search.toLowerCase();

      if (typeof value === 'string') {
        hasMatch = value.toLowerCase().search(s) !== -1;
      }

      if (isArr && !hasMatch) {
        const filtered = value.filter(i => {
          i = i && typeof i === 'object' ? Object.values(i).join(', ') : i;
          return typeof i === 'string' ? i.toLowerCase().search(s) !== -1 : false;
        });
        hasMatch = filtered.length > 0;
        hasExpandedMatch = hasMatch;
      }

      if (typeof value === 'object' && !isArr && !hasMatch) {
        hasMatch =
          Object.values(value).filter(i => {
            return i.toLowerCase().search(s) !== -1;
          }).length > 0;
        hasExpandedMatch = hasMatch;
      }

      if (hasExpandedMatch) {
        this.expanded.push(item);
      }

      return hasMatch;
    },
    getCustomFieldValue(customField, appointment) {
      if (!customField.value) {
        return '----';
      }

      return this.novaCore.getCustomFieldFormattedValue(customField, {
        [this.novaCore.CustomFieldType.Timestamp]: {
          timezone: appointment.dock.warehouse.timezone,
          formatAsMilitary: this.$isMilitaryTimeEnabled(this.$org)
        }
      });
    },
    toggleRowExpansion(item) {
      if (this.expanded.includes(item)) {
        const index = this.expanded.findIndex(i => i === item);
        this.expanded.splice(index, 1);
      } else {
        this.expanded.push(item);
      }
    },
    async search(trackEvent = true) {
      this.shouldShowSearchOptions = false;
      if (!this.canSearch) {
        this.searchResults = {};
        this.currentSearch = {};
        return;
      }
      const searchOptions = this.transformSearchOptions();
      this.isLoading = true;

      // This sucks, but in order for the search API to know the correct sort by column, it needs the table prefix
      // appointment's direct nested data has correct sql selector for backend - i.e. dock.name, loadtype.name,
      // but top level fields and deeper nested fields do not
      // i.e. start, refNumber need the prefix to be added - i.e. appointment.start, appointment.refNumber
      let sortClone = this.novaCore.deepClone(this.sort);
      const sortByParts = sortClone.sortBy?.split('.');
      if (sortByParts?.length < 2) {
        // Handle top level prefixes
        sortClone.sortBy = `appointment.${sortByParts[0]}`;
      } else if (sortByParts?.length > 2) {
        // Handle deeper level prefixes
        // e.g. dock.warehouse.name becomes warehouse.name
        sortClone.sortBy = sortByParts.slice(-2).join('.');
      }
      // End of sucky part ----------------------------------------------------
      const response = await axios
        .post('/search/appointments', {
          pagination: {
            size: this.options.itemsPerPage,
            from: (this.options.page - 1) * this.options.itemsPerPage
          },
          sort: sortClone,
          ...searchOptions
        })
        .finally(() => {
          if (trackEvent) {
            this.mixpanel.track(this.mixpanel.events.ACTION.SEARCH_APPOINTMENTS, {
              'Org Name': this.$org.name,
              'Org ID': this.$org.id,
              'Search Appointments Input': this.searchOptions.searchStr,
              'Search Appointments Filters': this.searchOptions,
              'Entry Point': isMobile() ? 'Mobile Search Page' : 'Desktop Search Page'
            });
          }
          this.isLoading = false;
        });
      this.shouldShowSearchOptions = false;
      this.searchResults = response?.data ?? {};
      this.currentSearch = this.novaCore.deepClone(this.searchOptions);
    },
    clearOptions() {
      this.searchOptions = {
        searchStr: '',
        refNumber: '',
        notes: '',
        tags: '',
        customFields: '',
        startDate: '',
        endDate: '',
        dockIds: [],
        scheduledStart: this.searchOptions.scheduledStart,
        scheduledEnd: this.searchOptions.scheduledEnd,
        statuses: []
      };
      this.currentSearch = {};
      this.selectedWarehouses = [];
      this.searchResults = {};
      this.shouldShowSearchOptions = false;
      this.options.page = 1;
    },
    toggleSearchOptions() {
      this.shouldShowSearchOptions = !this.shouldShowSearchOptions;
    },
    async getDataForSearchOptions() {
      const warehouseResponse = await this.services.warehouse.getWarehouses(
        {},
        {
          fields: ['name', 'id'],
          joins: ['docks||name,id']
        }
      );
      if (warehouseResponse?.warehouses) {
        this.warehouses = warehouseResponse.warehouses;
      }

      const triggerResponse = await axios.get('/custom-forms/trigger');
      this.warehouseTriggers = triggerResponse?.data?.data || [];
    },
    transformSearchOptions() {
      const searchOptions = this.novaCore.deepClone(this.searchOptions);
      if (searchOptions.dockIds?.length === 0) {
        delete searchOptions.dockIds;
      }

      if (!searchOptions.scheduledStart || !searchOptions.scheduledEnd) {
        delete searchOptions.scheduledStart;
        delete searchOptions.scheduledEnd;
      }

      if (searchOptions.users?.length > 0) {
        searchOptions.userIds = searchOptions.users.map(u => u.id);
        delete searchOptions.users;
      }
      return searchOptions;
    },
    getArrValue(key, arrVal) {
      let value = arrVal;
      if (key === 'dockIds') {
        const warehouse = this.selectedWarehouses.find(warehouse =>
          warehouse.docks.map(dock => dock.id).includes(value)
        );
        const dock = this.selectedDocks.find(dock => dock.id === arrVal);
        value = `${warehouse?.name} - ${dock?.name}`;
      }

      if (key === 'users') {
        value = `${arrVal.firstName} ${arrVal.lastName} - ${arrVal.company.name}`;
      }

      return value;
    },
    removeArrEl(key, index, val) {
      if (key === 'dockIds') {
        this.selectedDocks = this.selectedDocks.filter(dock => dock.id !== val);
      } else {
        this.searchOptions[key].splice(index, 1);
      }
      this.$nextTick(() => {
        this.search();
      });
    },
    hideSearchOptions(e) {
      const isDatePicker = !!e.target.closest('.v-picker');
      if (isDatePicker || e.target.className.indexOf('v-list-item') !== -1) {
        return false;
      }
      this.shouldShowSearchOptions = false;
    },
    regexSafeSearch() {
      if (this.filters.searchStr) {
        return _.escapeRegExp(this.filters.searchStr);
      }

      return this.filters.searchStr;
    },
    async appointmentUpdateHandler(appointmentData) {
      if (this.selectedAppointment?.id === appointmentData?.id) {
        await this.getAppointmentById();
      }

      let resultsCheck;
      if (appointmentData?.debounce?.count > 0) {
        resultsCheck = appointmentData.debounce.ids.some(appointmentId =>
          this.appointments.map(appointment => appointment.id).includes(appointmentId)
        );
      } else {
        resultsCheck = this.appointments
          .map(appointment => appointment.id)
          .includes(appointmentData?.id);
      }

      // Appointment update event comes without a payload,
      // therefore we must search again to refresh the page
      if (!appointmentData || (this.appointments?.length > 0 && resultsCheck)) {
        await this.search();
      }
    },
    async getAppointmentById() {
      await this.$store
        .dispatch('Appointments/getAppointmentById', this.selectedAppointment.id)
        .then(response => {
          this.selectedAppointment = response;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  async mounted() {
    this.$eventHub.$on(
      ['appointment-updated', 'update-Appointment'],
      this.appointmentUpdateHandler
    );
    await this.getDataForSearchOptions();
  },
  beforeMount() {
    // Avoid crossed context when opening the appointment modal
    this.$store.dispatch('Calendar/clearSelectedWarehouseData', []);
  },
  beforeDestroy() {
    this.$eventHub.$off(['appointment-updated', 'update-Appointment']);
  },
  watch: {
    sort: {
      handler() {
        this.search();
      },
      deep: true
    },
    searchOptions: {
      handler() {
        this.$nextTick(() => {
          if (!this.canSearch) {
            this.searchResults = {};
            this.currentSearch = {};
          }
        });
      },
      deep: true
    },
    selectedDocks() {
      this.searchOptions.dockIds = this.selectedDocks.map(dock => dock.id);
    },
    selectedWarehouses(newWarehouses, oldWarehouses) {
      // If there are selected warehouses, we want to add docks correctly
      if (this.selectedWarehouses.length > 0) {
        let newWarehouse = null;

        // Get most recently added warehouse
        if (newWarehouses.length > oldWarehouses.length) {
          newWarehouse = newWarehouses[newWarehouses.length - 1];
        }

        // If a warehouse was added, add those docks to the selected docks
        if (newWarehouse) {
          this.selectedDocks = [...this.selectedDocks, ...newWarehouse.docks];
        } else {
          // If a warehouse is removed, preserve previously selected docks of current selected warehouses
          this.selectedDocks =
            this.selectedDocks.length > 0
              ? this.availableDocks.filter(dock => {
                  return this.selectedDocks.map(selectedDock => selectedDock.id).includes(dock.id);
                })
              : this.availableDocks;
          this.searchOptions.dockIds = this.availableDocks.map(dock => dock.id);
        }
      } else {
        this.searchOptions.dockIds = [];
        this.selectedDocks = [];
      }
    },
    options() {
      this.search();
    }
  },
  filters: {
    highlight(value, query, itemId, type) {
      if (typeof value !== 'string') {
        return value;
      }

      let r = value?.replace(
        new RegExp(query, 'ig'),
        v => `<span class='orange lighten-3'>${v}</span>`
      );

      if (type === 'tag') {
        r = `${r}`;
      }

      return r;
    }
  }
};
</script>
