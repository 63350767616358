var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        key: _vm.renderKey,
        attrs: {
          header: "Clone Custom Fields",
          scrollable: false,
          loading: false,
          "loading-message": "Saving...",
        },
        on: { close: _vm.close },
        scopedSlots: _vm._u(
          [
            !_vm.externalActivator
              ? {
                  key: "activator",
                  fn: function (slotProps) {
                    return [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "", disabled: !_vm.readOnly },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      "div",
                                      _vm._g({}, on),
                                      [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  disabled: _vm.readOnly,
                                                  color: "secondary",
                                                  large: "",
                                                },
                                              },
                                              "v-btn",
                                              slotProps.attrs,
                                              false
                                            ),
                                            slotProps.on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "ml-0",
                                                attrs: { left: "", small: "" },
                                              },
                                              [_vm._v("mdi-content-copy")]
                                            ),
                                            _vm._v(" Clone Fields "),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.novaCore.getRoleActionError())),
                          ]),
                        ]
                      ),
                    ]
                  },
                }
              : null,
            {
              key: "body",
              fn: function () {
                return [
                  _c(
                    "v-alert",
                    {
                      staticClass: "py-4 mt-4 mb-8",
                      attrs: {
                        border: "top",
                        dense: "",
                        "colored-border": "",
                        type: "info",
                        color: "info",
                        elevation: "2",
                      },
                    },
                    [
                      _c("p", [
                        _vm._v(
                          " Selected Custom fields will be appended to this warehouse. "
                        ),
                        _c("br"),
                        _vm._v(" Duplicate fields will be omitted. "),
                      ]),
                    ]
                  ),
                  _c("h4", { staticClass: "mb-0 pb-0" }, [
                    _vm._v("Warehouse to Clone From"),
                  ]),
                  _c("warehouse-select", {
                    staticClass: "pb-5",
                    attrs: {
                      "multi-select": false,
                      "disable-if-no-docks": false,
                      "disabled-warehouse-ids": [_vm.targetWarehouse.id],
                      "show-create-dock-button": false,
                      disabled: _vm.loadingWarehouse,
                      "external-loading": _vm.loadingWarehouse,
                      "hide-icon": "",
                    },
                    model: {
                      value: _vm.selectedWarehouse,
                      callback: function ($$v) {
                        _vm.selectedWarehouse = $$v
                      },
                      expression: "selectedWarehouse",
                    },
                  }),
                  _c("h4", { staticClass: "mb-0 pb-0" }, [
                    _vm._v("Custom Fields to Clone"),
                  ]),
                  _c(
                    "v-tooltip",
                    {
                      attrs: {
                        top: "",
                        disabled: !!_vm.customFieldsTemplate.length,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on }) {
                            return [
                              _c(
                                "div",
                                _vm._g({}, on),
                                [
                                  _c("custom-field-select", {
                                    staticClass: "mt-0 pt-0",
                                    attrs: {
                                      multiple: "",
                                      label: "Select custom fields",
                                      placeholder: "Select custom fields",
                                      "item-text": "label",
                                      "item-value": "name",
                                      disabled:
                                        !_vm.selectedWarehouse ||
                                        !_vm.customFieldsTemplate,
                                      "return-object": "",
                                      "single-line": "",
                                      "auto-select": "",
                                      customFields: _vm.customFieldsTemplate,
                                    },
                                    model: {
                                      value: _vm.selectedFields,
                                      callback: function ($$v) {
                                        _vm.selectedFields = $$v
                                      },
                                      expression: "selectedFields",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      !_vm.selectedWarehouse
                        ? _c("span", [_vm._v("Please select a warehouse")])
                        : _c("span", [
                            _vm._v(
                              _vm._s(_vm.selectedWarehouse.name) +
                                " does not have any custom fields"
                            ),
                          ]),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "dialog-actions",
              fn: function () {
                return [
                  _c("action-group", {
                    attrs: {
                      "confirm-label": "Clone Fields",
                      "disable-confirm": _vm.selectedFields.length === 0,
                    },
                    on: { confirm: _vm.submit, cancel: _vm.close },
                  }),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      },
      "dialog-base",
      [_vm.$props, _vm.$attrs],
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }